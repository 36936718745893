import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import backIcon from './left-arrow.png';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  Modal,
  Button,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  sendShippingRequestByCustomer,
} from './ListingPage.duck';
import SectionAvatar from './SectionAvatar';
import ActionBarMaybe from './ActionBarMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isArray } from 'lodash';
import { parse, stringify } from '../../util/urlHelpers';
import InstaEditMenu from '../ManageListingsPage/ManageListingCard/InstaEditMenu';
import { post } from '../../util/api';
import gif1 from './gif1.gif';
import gif2 from './gif2.gif';
import gif3 from './gif3.gif';
import gif4 from './gif4.gif';
import penIcon from './pen.jpeg';
import { findConfigForSelectFilter } from '../../util/search';
import css from './ListingPage.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const co2SavedPerItem = process.env.REACT_APP_CO2_SAVED_PER_ITEM ?? 0;
const litersSavedPerItem = process.env.REACT_APP_LITERS_SAVED_PER_ITEM ?? 0;

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      successModalOpen: false,
      shippingModalOpen: false,
      showShare: false,
      views: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSendShippingRequest = this.onSendShippingRequest.bind(this);
  }
  //
  componentDidMount() {
    const { params: rawParams } = this.props;

    const listingId = new UUID(rawParams.id);

    return post('/api/get-views-number', { listingId: rawParams.id })
      .then(resp => {
        const foundRow = resp[0];

        if (foundRow) {
          const viewsNumber = Number(foundRow.views);
          const newViewsNumber = viewsNumber + 1;
          const body = {
            tableName: 'views',
            keys: ['views'],
            values: [newViewsNumber],
            primaryKey: 'id',
            primaryKeyValue: rawParams.id,
          };
          return post('/api/edit-row', body)
            .then(resp => {
              this.setState({ views: newViewsNumber });
            })
            .catch(e => {
              return console.log(e);
            });
        } else {
          //If the db doesn't have a row corresponding to the current listing,
          //we take the views number from publicData and create a new row.
          //This is a refactor step to move views from publicData to the pg database
          //or
          //this can be the inital step when the listing doesn't have any views
          return sdk.listings
            .show({ id: listingId })
            .then(res => {
              const views = res.data.data.attributes.publicData.views ?? 0;
              const newViews = views + 1;
              const body = {
                keys: ['id', 'views'],
                values: [rawParams.id, newViews],
                tableName: 'views',
              };
              return post('/api/add-new-row', body)
                .then(resp => {
                  this.setState({ views: newViews });
                })
                .catch(e => {
                  return console.log(e);
                });
            })
            .catch(e => {
              return console.log(e);
            });
        }
      })
      .catch(e => {
        return console.log(e);
      });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, quantity: quantityRaw, deliveryMethod, ...otherOrderData } = values;
    const bookingDatesMaybe = bookingDates
      ? {
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
        }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: Number.parseInt(quantityRaw, 10),
        deliveryMethod,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onSendShippingRequest(protectedDataInfo) {
    const { params, sendShippingRequest } = this.props;
    const listingId = new UUID(params.id);

    return sendShippingRequest(listingId, protectedDataInfo)
      .then(resp => {
        this.setState({
          successModalOpen: true,
        });
      })
      .catch(e => {
        throw e;
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
    } = this.props;

    const { shippingModalOpen, showShare } = this.state;
    const setShowShare = value => {
      this.setState({ showShare: value });
    };
    const setShippingModalOpen = value => this.setState({ shippingModalOpen: value });
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      //description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const description = publicData.description ?? currentListing.attributes.description;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const amenityOptions = findOptionsForSelectFilter('amenities', customConfig.filters);
    const categoryOptions = findOptionsForSelectFilter('category', customConfig.filters);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;
    const views = this.state.views;

    const editParams = {
      id: listingId.uuid,
      slug: listingSlug,
      type: listingType,
      tab: listingTab,
    };
    const editButton = isOwnListing && (
      <NamedLink className={css.editWrapper} name="EditListingPage" params={editParams}>
        <img src={penIcon} className={css.penIcon} />
        <FormattedMessage id={'ListingPage.editListing'} />
      </NamedLink>
    );

    const productSubcategoryConfig = findConfigForSelectFilter(
      'productSubcategory',
      config.custom.filters
    );
    const productSubcategory =
      publicData &&
      publicData.productSubcategory &&
      productSubcategoryConfig.options.find(o => o.key === publicData.productSubcategory).label;

    // const compositionConfig = findConfigForSelectFilter('composition', config.custom.filters);
    // const composition =
    //   publicData &&
    //   publicData.composition &&
    //   isArray(publicData.composition) &&
    //   publicData.composition
    //     .map(c => compositionConfig.options.find(o => o.key === c).label)
    //     .join(', ');

    const conditionConfig = findConfigForSelectFilter('condition', config.custom.filters);
    const condition =
      publicData &&
      publicData.condition &&
      conditionConfig.options.find(o => o.key === publicData.condition).label;

    const defects = publicData && publicData.defects;

    const productColorConfig = findConfigForSelectFilter('productColor', config.custom.filters);
    const productColor =
      publicData &&
      publicData.productColor &&
      isArray(publicData.productColor) &&
      publicData.productColor
        .map(c => {
          const foundColor = productColorConfig.options.find(o => o.key === c);
          if (foundColor.label?.props?.id) {
            return intl.formatMessage({ id: foundColor.label?.props?.id });
          } else {
            return foundColor.label;
          }
        })
        .join(', ');

    const sizeConfig = findConfigForSelectFilter('size', config.custom.filters);
    const size =
      publicData &&
      publicData.size &&
      sizeConfig.options.find(o => o.key === publicData.size).label;

    const oneSizeSystem =
      publicData?.sizeSystem === 'oneSize' && intl.formatMessage({ id: 'sizeSystem.oneSize' });

    const keyCategoryConfig = findConfigForSelectFilter('keyCategory', config.custom.filters);
    const keyCategory =
      publicData &&
      publicData.keyCategory &&
      keyCategoryConfig.options.find(o => o.key === publicData.keyCategory[0])?.label;

    const productCategoryConfig = findConfigForSelectFilter(
      'productCategory',
      config.custom.filters
    );
    const productCategory =
      publicData &&
      publicData.productCategory &&
      productCategoryConfig.options.find(o => o.key === publicData.productCategory).label;

    const productTypeConfig = findConfigForSelectFilter('productType', config.custom.filters);
    const productType =
      publicData &&
      publicData.productType &&
      productTypeConfig.options.find(o => o.key === publicData.productType).label;
    const isOrderOpen = !!parse(location.search).orderOpen;
    const toogleMenu = () => {
      return setShowShare(!showShare);
    };
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                {/* {currentListing.id ? (
                  <ActionBarMaybe
                    className={css.actionBarForProductLayout}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                  />
                ) : null} */}
                {!shippingModalOpen && !this.state.successModalOpen && (
                  <div
                    className={css.backButtonWrapper}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        history.back();
                      }
                    }}
                  >
                    <img src={backIcon} className={css.backIcon} />
                  </div>
                )}

                <SectionGallery
                  listing={currentListing}
                  views={views}
                  shippingModalOpen={shippingModalOpen}
                  setShowShare={setShowShare}
                />

                <div className={css.leftBottomSectionWrapper}>
                  <div className={css.productMobileHeading}>
                    <SectionHeading
                      priceTitle={priceTitle}
                      formattedPrice={formattedPrice}
                      richTitle={richTitle}
                      category={category}
                      authorLink={authorLink}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      listingIdObj={currentListing.id}
                    />
                  </div>
                  <div className={css.desktopHeading}>
                    <h2 className={css.listingTitle}>{richTitle}</h2>

                    <div>
                      <FormattedMessage id="ListingPage.savingsLabel" />
                    </div>

                    <div className={css.savingsIconsWrapper}>
                      <div className={css.savingsLine}>
                        <div className={css.savingsItem}>
                          <img src={gif1} className={css.gif} />
                          <div className={css.savingsItemRight}>
                            <span className={css.savingsTopInfo}>
                              {co2SavedPerItem}
                              <FormattedMessage id="ListingPage.kgMesure" />
                            </span>
                            <span className={css.savingsBottomInfo}>
                              <FormattedMessage id="ListingPage.co2Label" />
                            </span>
                          </div>
                        </div>

                        <div className={css.savingsItem}>
                          <img src={gif3} className={css.gif} />
                          <div className={css.savingsItemRight}>
                            <span className={css.savingsTopInfo}>
                              {litersSavedPerItem} <FormattedMessage id="ListingPage.litersLabel" />
                            </span>
                            <span className={css.savingsBottomInfo}>
                              <FormattedMessage id="ListingPage.h2oSavedLabel" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <SectionDescriptionMaybe description={description} listingTitle={richTitle} />
                    <SectionDetailsMaybe publicData={publicData} customConfig={customConfig} />
                    <SectionFeaturesMaybe
                      extendedDataKey="amenities"
                      options={amenityOptions}
                      publicData={publicData}
                    />
                    <SectionRulesMaybe publicData={publicData} />
                    <SectionMapMaybe
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing.id}
                    /> */}

                  <div className={css.mProductInfoWrapper}>
                    {description && <div className={css.description}>{description}</div>}

                    <div className={css.type}>
                      <strong>
                        <FormattedMessage id="ListingPage.typeLabel" />
                      </strong>
                      <span>
                        {productSubcategory ?? <FormattedMessage id="ListingPage.notProvided" />}
                      </span>
                    </div>

                    <div className={css.brand}>
                      <strong>
                        <FormattedMessage id="ListingPage.brandLabel" />
                      </strong>
                      <span>
                        {publicData?.brand ?? <FormattedMessage id="ListingPage.notProvided" />}
                      </span>
                    </div>

                    <div className={css.otherInfo}>
                      <Accordion
                        sx={{
                          background: 'transparent',
                          border: 'none',
                          boxShadow: 'none',
                          borderRadius: '0px !important',
                          '& .MuiButtonBase-root': {
                            padding: '0px',
                          },
                          '& .MuiAccordionDetails-root': {
                            padding: '0px',
                          },
                        }}
                        // MuiAccordionDetails-root css-15v22id-MuiAccordionDetails-root
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <strong className={css.accordionLabel}>
                              <FormattedMessage id="ListingPage.productDetailsLabel" />
                            </strong>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className={css.otherInfoItems}>
                              <div className={css.otherInfoItem}>
                                <div className={css.otherInfoItemLabel}>
                                  <FormattedMessage id="ListingPage.sizeLabel" />
                                </div>
                                <div className={css.otherInfoItemValue}>
                                  {oneSizeSystem
                                    ? oneSizeSystem
                                    : size ?? <FormattedMessage id="ListingPage.notProvided" />}
                                </div>
                              </div>

                              <div className={css.otherInfoItem}>
                                <div className={css.otherInfoItemLabel}>
                                  <FormattedMessage id="ListingPage.colourLabel" />
                                </div>
                                <div className={css.otherInfoItemValue}>
                                  {productColor ?? (
                                    <FormattedMessage id="ListingPage.notProvided" />
                                  )}
                                </div>
                              </div>

                              {/* <div className={css.otherInfoItem}>
                                <div className={css.otherInfoItemLabel}>Composition</div>
                                <div className={css.otherInfoItemValue}>
                                  {composition ?? 'Not provided'}
                                </div>
                              </div> */}

                              <div className={css.otherInfoItem}>
                                <div className={css.otherInfoItemLabel}>
                                  <FormattedMessage id="ListingPage.conditionLabel" />
                                </div>
                                <div className={css.otherInfoItemValue}>
                                  {condition ?? <FormattedMessage id="ListingPage.notProvided" />}
                                </div>
                              </div>

                              {defects && (
                                <div className={css.otherInfoItem}>
                                  <div className={css.otherInfoItemLabel}>
                                    <FormattedMessage id="ListingPage.defectsLabel" />
                                  </div>
                                  <div className={css.otherInfoItemValue}>{defects}</div>
                                </div>
                              )}
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className={css.categories}>
                      <strong>
                        <FormattedMessage id="ListingPage.categoriesLabel" />
                      </strong>
                      {keyCategory && (
                        <a href={`/s?pub_keyCategory=has_any%3A${publicData.keyCategory}`}>
                          {keyCategory}{' '}
                        </a>
                      )}
                      {productCategory && (
                        <a href={`/s?pub_productCategory=${publicData.productCategory}`}>
                          {productCategory}{' '}
                        </a>
                      )}
                      {productSubcategory && (
                        <a href={`/s?pub_productSubcategory=${publicData.productSubcategory}`}>
                          {productSubcategory}{' '}
                        </a>
                      )}
                      {productType && (
                        <a href={`/s?pub_productType=${publicData.productType}`}>{productType}</a>
                      )}
                    </div>
                  </div>
                  <SectionAuthorMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                </div>
              </div>

              <div className={css.orderColumnForProductLayout}>
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleOrderSubmit}
                  title={bookingTitle}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={this.onContactUser}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  onSendShippingRequest={this.onSendShippingRequest}
                  editButton={editButton}
                  setShippingModalOpen={setShippingModalOpen}
                  shippingModalOpen={shippingModalOpen}
                  oneSizeSystem={oneSizeSystem}
                  editParams={editParams}
                />
              </div>

              <InstaEditMenu
                isMenuOpen={showShare}
                toogleMenu={toogleMenu}
                hideToogleDots={true}
                noBottom={true}
              >
                <div className={css.mobileEditButtonsWrapper}>
                  <div
                    className={css.mobileEditButton}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        navigator.clipboard.writeText(`${window.location.href}`);
                      }
                      setShowShare(!showShare);
                    }}
                  >
                    <FormattedMessage id="ListingPage.Copy" />
                  </div>

                  <a
                    href="instagram://"
                    className={css.mobileEditButton}
                    onClick={() => setShowShare(!showShare)}
                  >
                    <FormattedMessage id="ManageListingCard.InstagramShare" />
                  </a>

                  <a
                    href="whatsapp://"
                    className={css.mobileEditButtonLast}
                    onClick={() => setShowShare(!showShare)}
                  >
                    <FormattedMessage id="ManageListingCard.WhatsappShare" />
                  </a>

                  {/* <div className={css.mobileEditButtonLast} onClick={() => {}}>
              <FormattedMessage id="ManageListingCard.TiktokShare" />
            </div> */}
                </div>
              </InstaEditMenu>

              <Modal
                isOpen={this.state.successModalOpen}
                onClose={() => {
                  this.setState({
                    successModalOpen: false,
                  });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div>
                  <center>
                    <h2 className={css.successModalTitle}>
                      {intl.formatMessage({ id: 'ListingPage.successModalTitle' })}
                    </h2>
                    <h2>
                      {intl.formatMessage(
                        { id: 'ListingPage.successModalMessage' },
                        { inboxIcon: <LocalPostOfficeOutlinedIcon /> }
                      )}
                    </h2>

                    <Button
                      className={css.successModalSubmit}
                      type="button"
                      onClick={() => this.setState({ successModalOpen: false })}
                    >
                      <FormattedMessage id="ListingPage.successModalSubmitMessage" />
                    </Button>
                  </center>
                </div>
              </Modal>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  sendShippingRequest: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  sendShippingRequest: (listingId, protectedDataInfo) =>
    dispatch(sendShippingRequestByCustomer(listingId, protectedDataInfo)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
