import React from 'react';
import { css } from '@emotion/css';
import './InstaEditMenu.css';

const easeSlow = css`
  transition: all 450ms ease-in-out;
`;

const menuBtn = css`
  z-index: 3;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  ${easeSlow};
  &.closer {
    transform: rotate(180deg);
  }
`;

const btnLine = css`
  width: 4px;
  height: 4px;
  border-radius: 111px;
  margin: 0 2px;
  background-color: #4a4a4a;
  ${easeSlow};
  &.closer {
    display: none;
//     background-color: #e76e81;
//     &:nth-child(1) {
//       transform: rotate(45deg) translate(4px, 0px);
//       width: 20px;
//     }
//     &:nth-child(2) {
//       transform: translateX(-8px);
//     }
//     &:nth-child(3) {
//       transform: rotate(-45deg) translate(4px, 0px);
//       width: 20px;
//     }
//   }
`;

const menuOverlay = css`
  z-index: 99999;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  height: auto;
  width: 100%;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
  &.show {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transform: translateY(0%);
  }
  nav {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      height: 30px;
      text-decoration: none;
      color: #eb4c54;
      cursor: pointer;
      transition: all 150ms ease-in-out;
      &:hover {
        color: #f28eba;
      }
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const greyBackground = css`
  position: fixed;
  z-index: 999;
  background-color: grey;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
`;

const noDisplay = css`
  display: none;
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, isMenuOpen, toogleMenu, hideToogleDots, noBottom } = this.props;
    return (
      <React.Fragment>
        <div onClick={toogleMenu} className={`${isMenuOpen ? greyBackground : noDisplay}`}></div>
        {!hideToogleDots && (
          <div className={`${menuBtn} ${isMenuOpen ? 'closer' : null}`} onClick={toogleMenu}>
            <div className={`${btnLine} ${isMenuOpen ? 'closer' : null}`} />
            <div className={`${btnLine} ${isMenuOpen ? 'closer' : null}`} />
            <div className={`${btnLine} ${isMenuOpen ? 'closer' : null}`} />
          </div>
        )}

        <div className={`${menuOverlay} ${isMenuOpen ? 'show' : null}`}>
          <div className={'closeMenuWrapper'} onClick={toogleMenu}>
            <div className="dragBar"></div>
          </div>
          <nav style={{ paddingTop: '20px', paddingBottom: noBottom ? '40px' : '120px' }}>
            {children}
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default Menu;
