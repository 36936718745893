import React from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, views, shippingModalOpen, setShowShare } = props;

  //images reorder feature - delete later if we don't want this feature anymore
  // const imagesOrder = listing?.attributes?.publicData?.imagesOrder;
  //---
  const images = listing.images;

  //if we have a selected pic for the background removal
  //we replace the url of the first pic with the url of our selected pic
  const selectedMainPic = false; //TODO photo feature listing?.attributes?.publicData?.selectedMainPic;
  if (selectedMainPic && images?.length > 0) {
    const firstPic = images[0];
    const variantsKeys = Object.keys(firstPic.attributes.variants);
    variantsKeys.forEach(v => {
      if (firstPic.attributes.variants[`${v}`].url !== selectedMainPic.url) {
        firstPic.attributes.variants[`${v}`].url = selectedMainPic.url;
      }
    });
  }

  //images reorder feature - delete later if we don't want this feature anymore
  // const imagesReordered = imagesOrder
  //   ? imagesOrder.map(i => {
  //       const found = images.find(img => {
  //         return img.id.uuid === i.id;
  //       });

  //       return found;
  //     })
  //   : images;
  //----

  const imagesReordered = images;

  const { variantPrefix } = config.listing;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <ListingImageGallery
      rootClassName={css.productGallery}
      images={imagesReordered}
      imageVariants={imageVariants}
      thumbnailVariants={thumbnailVariants}
      views={views}
      shippingModalOpen={shippingModalOpen}
      setShowShare={setShowShare}
    />
  );
};

export default SectionGallery;
